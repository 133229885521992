<template>
  <div style="min-height:550px;">
    <cEventEdit v-show="selectedEventId" v-model="selectedEventId" />
    <div class="row">
      <div class="col-xs-3">
        <button class="btn" @click="previousYear">Previous</button>
      </div>
      <div class="col-xs-6 text-center calendar-title">
        <span class="hidden-xs">{{ title }}</span> <span id="date-display">{{ year }}</span>
        <img v-if="loading" id="calendar-wait" src="/img/wait_circle_25.GIF" alt="Please wait..." />
      </div>
      <div class="col-xs-3 text-right">
        <button class="btn" @click="nextYear">Next</button>
      </div>
    </div>
    <button v-show="isAuthenticated" class="btn" style="margin-top:10px;" @click="addEvent">Add</button>

    <div class="row">
      <div class="col-sm-12">
        <p id="message-error" class="bg-danger"></p>
        <table id="events-table" class="events-list">
          <thead>
            <tr>
              <td><div class="event-date text-center">Date</div></td>
              <td>Description</td>
              <td class="hidden-xs">Start</td>
              <td></td>
            </tr>
          </thead>
          <tbody id="events-table-body" v-for="e in events" :key="e.Id">
            <tr>
              <td>
                <div class="event-date" @click="editEvent(e.Id)">
                  <div class="event-day">{{ e.StartDate | toDD }}</div>
                  <div class="event-month">{{ e.StartDate | toMonthName }}</div>
                </div>
              </td>
              <td>{{ e.Name }}</td>
              <td class="hidden-xs">{{ e.StartDate | toHHMMAM }}</td>
              <td>
                <div v-show="e.Agenda"><a :href="e.AgendaUrl" target="_blank">Agenda</a></div>
                <div v-show="e.Minutes"><a :href="e.MinutesUrl" target="_blank">Minutes</a></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import * as _publicService from "@/services/publicService";
import * as _utility from "@/utility";

import cEventEdit from "@/components/cEventEdit.vue";

export default {
  name: "cEvents",
  components: { cEventEdit },

  props: ["title", "sortasc"],
  data: () => ({
    events: [],
    year: 0,
    loading: false,
    selectedEventId: null,
  }),
  methods: {
    load() {
      let asc = this.sortasc !== undefined ? this.sortasc : true;
      this.loading = true;
      if (this.year === 0) {
        let d = new Date();
        this.year = d.getFullYear();
      }
      _publicService.getEvents(this.year).then((response) => {
        if (!response.Success) {
          this.$store.commit("dispatchMessage", {
            color: response.SuccessStatus === 2 ? "danger" : "warning",
            text: response.Message,
          });
        } else {
          if (!asc) {
            console.log("sorting events descending...");
            //StartDate: "2021-01-28T12:00:00"
            response.Events = response.Events.sort((a, b) => {
              let ad = new Date(a.StartDate);
              let bd = new Date(b.StartDate);
              if (ad < bd) return 1;
              if (ad > bd) return -1;
              return 0;
            });
          }

          this.events = response.Events;
        }
        this.loading = false;
      });
    },
    addEvent() {
      if (!this.isAuthenticated) return;
      this.selectedEventId = _utility.emptyGuid;
    },
    editEvent(id) {
      if (!this.isAuthenticated) return;
      this.selectedEventId = id;
    },
    previousYear() {
      this.year = this.year - 1;
      this.load();
    },
    nextYear() {
      this.year = this.year + 1;
      this.load();
    },
  },
  computed: {
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated();
    },
  },
  watch: {
    selectedEventId: function(newVal, oldVal) {
      if (oldVal && newVal == null) this.load();
    },
  },
  mounted: function() {
    this.load();
  },
};
</script>
