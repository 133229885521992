<template>
  <div>
    <!-- Page Title -->
    <div class="section section-breadcrumbs">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1>Directors</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="row">
          <cDirectorElements elementkey="Directors" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cDirectorElements from "@/components/cDirectorElements.vue";

export default {
  name: "vDirectors",
  components: { cDirectorElements },
  props: [],
  data: () => ({}),
  methods: {},
  computed: {},
  mounted: function() {},
};
</script>
