<template>
  <div>
    <!-- Page Title -->
    <div class="section section-breadcrumbs">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1>Documents</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="row ">
          <div class="col-sm-6">
            <cEvents title="Documents" />
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12 text-center calendar-title">
                General Documents
              </div>
            </div>
            <div class="row">
              <div class="col-sm-offset-3 col-sm-8">
                <cElements elementkey="Documents" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cEvents from "@/components/cEvents.vue";
import cElements from "@/components/cElements.vue";

export default {
  name: "vDocuments",
  components: { cEvents, cElements },
  props: [],
  data: () => ({}),
  methods: {},
  computed: {},
  mounted: function() {},
};
</script>
