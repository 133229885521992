<template>
  <div class="edit-container">
    <div class="card full">
      <div class="card-header">Edit Event</div>
      <div class="card-body">
        <div class="card-text">
          <form>
            <div class="row">
              <div class="col-xs-12">
                <label for="name" class="form-label">Name</label>
                <input type="text" class="form-control" id="name" v-model="event.Name" />
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-6">
                <label for="date" class="form-label">Date</label>
                <input type="date" class="form-control" id="date" v-model="event.StartDate" />
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-6">
                <label for="starttime" class="form-label">Start Time</label>
                <input type="time" class="form-control" id="starttime" v-model="event.StartTime" />
              </div>
              <div class="col-xs-12 col-sm-6">
                <label for="endtime" class="form-label">End Time</label>
                <input type="time" class="form-control" id="endtime" v-model="event.EndTime" />
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <label for="location" class="form-label">Location</label>
                <input type="text" class="form-control" id="location" v-model="event.Location" />
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <label for="description" class="form-label">Description</label>
                <input type="text" class="form-control" id="description" v-model="event.Description" />
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-6">
                <label for="agenda" class="form-label">Has Agenda</label>
                <input type="checkbox" class="form-control" id="agenda" v-model="event.Agenda" />
              </div>
              <div class="col-xs-12 col-sm-6">
                <label for="minutes" class="form-label">Has Minutes</label>
                <input type="checkbox" class="form-control" id="minutes" v-model="event.Minutes" />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="card-footer ">
        <div class="row">
          <div class="col-xs-12 col-sm-4">
            <button type="button" class="btn btn-primary" @click="close" :disabled="loading">Cancel</button>
          </div>
          <div class="col-xs-12 col-sm-8 text-right">
            <img v-if="loading" src="/img/wait_circle_25.GIF" alt="Please wait..." />
            <button type="button" class="btn btn-danger" style="margin-right:15px;" v-show="event.Id != '00000000-0000-0000-0000-000000000000'" @click="deleteEvent" :disabled="loading">Delete</button>
            <button type="button" class="btn btn-primary" @click="saveEvent" :disabled="loading">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _adminService from "@/services/adminService";
import * as _utility from "@/utility";

const _defaultEvent = {
  Id: _utility.emptyGuid,
  Name: "",
  EventStart: null,
  EventEnd: null,
  StartDate: null,
  StartTime: null,
  EndTime: null,
  Location: "",
  Description: "",
  Agenda: false,
  Minutes: false,
};

export default {
  name: "cEventEdit",
  components: {},
  props: ["value"],
  data: () => ({
    event: _defaultEvent,
    loading: false,
  }),
  methods: {
    load() {
      this.loading = true;
      if (this.event.Id == _utility.emptyGuid) {
        this.event = { ..._defaultEvent };
        this.event.Name = "Board of Directors Meeting";
        this.event.StartDate = _utility.getTodayString();
        this.event.StartTime = "12:00";
        this.event.EndTime = "14:00";
        this.loading = false;
        return;
      }
      _adminService.getEvent(this.event.Id).then((response) => {
        if (!response.Success) {
          alert(response.FailureInformation);
        } else {
          let s = response.Event.EventStart.toString();
          let e = response.Event.EventEnd.toString();
          response.Event.StartDate = s.substr(0, s.indexOf("T"));
          response.Event.StartTime = s.substr(s.indexOf("T") + 1);
          response.Event.EndTime = e.substr(e.indexOf("T") + 1);

          this.event = response.Event;
        }
        this.loading = false;
      });
    },
    deleteEvent() {
      this.loading = true;
      _adminService.deleteEvent(this.event.Id).then((response) => {
        if (!response.Success) {
          alert(response.FailureInformation);
        } else {
          this.close();
        }
        this.loading = false;
      });
    },
    saveEvent() {
      this.loading = true;
      let data = {
        Id: this.event.Id,
        Name: this.event.Name,
        EventStart: this.event.StartDate + "T" + this.event.StartTime,
        EventEnd: this.event.StartDate + "T" + this.event.EndTime,
        Location: this.event.Location,
        Description: this.event.Description,
        Agenda: this.event.Agenda,
        Minutes: this.event.Minutes,
      };
      _adminService.saveEvent(data).then((response) => {
        if (!response.Success) {
          alert(response.FailureInformation);
        } else {
          this.event.Id = response.EventId;
          this.close();
        }
        this.loading = false;
      });
    },
    close() {
      this.$emit("input", null);
    },
  },
  computed: {
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated();
    },
  },
  watch: {
    value: {
      handler: function(newVal, oldVal) {
        if (!newVal) return;
        if (newVal != oldVal) {
          this.event.Id = newVal;
          this.load();
        }
      },
      deep: true,
    },
  },
  mounted: function() {
    this.load();
  },
};
</script>

<style scoped>
.edit-container {
  position: fixed;
  background-color: gray;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  padding: 10px;
}
</style>
