<template>
  <div>
    <div class="section section-breadcrumbs">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1>LEGAL NOTICES AND DISCLAIMERS</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="row service-wrapper-row">
          <div class="col-sm-12">
            <p>
              PLEASE READ THE FOLLOWING LEGAL STATEMENT. BY USING THIS WEB SITE, YOU ARE AGREEING TO THE TERMS BELOW. THESE POLICIES MAY CHANGE WITHOUT NOTICE:
            </p>
            <ol style="list-style: upper-latin;">
              <li>
                THE CONTENT CONTAINED IN THIS WEBSITE IS PROVIDED BY HORSEPEN BAYOU MUNICIPAL UTILITY DISTRICT OF HARRIS COUNTY, TEXAS, (THE “ENTITY”) AS A SERVICE TO THE PUBLIC. THIS WEBSITE AND THE INFORMATION CONTAINED HEREIN SHOULD NOT BE RELIED
                UPON OR USED AS INFORMATION FOR THE PURPOSES OF SECURITIES DISCLOSURE ABOUT THE ENTITY OR ITS FINANCIAL CONDITION. PERSONS SHOULD NOT RELY UPON THIS INFORMATION WHEN CONSIDERING WHETHER TO BUY, SELL, OR HOLD BONDS ISSUED BY THE
                ENTITY. ALL INFORMATION CONTAINED HEREIN SPEAKS ONLY AS OF THE DATE INDICATED. THE ENTITY ASSUMES NO DUTY TO UPDATE ANY INFORMATION CONTAINED HEREIN.
              </li>
              <li>
                LINKED SITES ARE NOT UNDER THE CONTROL OF THIS ENTITY, AND THE ENTITY IS NOT RESPONSIBLE FOR THE CONTENTS OF ANY LINKED SITE. LINKS ARE PROVIDED ONLY AS A CONVENIENCE, AND THE INCLUSION OF ANY LINK DOES NOT IMPLY THE ENDORSEMENT BY
                THE ENTITY OF THE SITE.
              </li>
              <li>
                THIS ENTITY DOES NOT WARRANT OR MAKE REPRESENTATIONS OR ENDORSEMENTS AS TO THE QUALITY, CONTENT, ACCURACY, OR COMPLETENESS OF THE INFORMATION, TEXT, GRAPHICS, LINKS AND OTHER ITEMS CONTAINED ON THIS WEBSITE OR ANY OTHER WEBSITE. SUCH
                MATERIALS HAVE BEEN COMPILED FROM A VARIETY OF SOURCES, AND ARE SUBJECT TO CHANGE WITHOUT NOTICE FROM THE ENTITY AS A RESULT OF UPDATES AND CORRECTIONS.
              </li>
              <li>
                TO THE EXTENT ALLOWED BY TEXAS LAW, COMMUNICATIONS MADE THROUGH E-MAIL OR OTHER MESSAGING SYSTEM SHALL IN NO WAY BE DEEMED TO CONSTITUTE LEGAL NOTICE TO THE ENTITY OR ANY OF ITS OFFICERS, EMPLOYEES, AGENTS, OR REPRESENTATIVES, WITH
                RESPECT TO ANY EXISTING OR POTENTIAL CLAIM OR CAUSE OF ACTION AGAINST THE ENTITY OR ANY OF ITS OFFICERS, EMPLOYEES, AGENTS, OR -4- 843843 REPRESENTATIVES, WHERE NOTICE TO THE ENTITY IS REQUIRED BY ANY STATE OR LOCAL LAWS, RULES, OR
                REGULATIONS.
              </li>
              <li>
                ANY USE OF THIS WEBSITE SHALL BE DEEMED MADE IN THE STATE OF TEXAS, U.S.A., REGARDLESS OF THE LOCATION OF THE USER. THE USER AGREES THAT ANY DISPUTE WITH THE ENTITY SHALL BE BROUGHT BY THE USER EXCLUSIVELY IN THE STATE OR FEDERAL
                COURT IN WHICH THE ENTITY IS PRINCIPALLY LOCATED. THE USER HEREBY AGREES THAT SUCH VENUE IS APPROPRIATE. ANY USE OF THIS WEBSITE IS GOVERNED BY THE LAWS IN FORCE IN THE STATE OF TEXAS. THE USER HEREBY AGREES THAT APPLYING TEXAS LAW TO
                THE USE OF THIS WEBSITE IS APPROPRIATE.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _userService from "@/services/userService";

export default {
  mounted: function() {
    localStorage.setItem("hp.legal", true);
  },
};
</script>
